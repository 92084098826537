import './credits.css';

function Credits(props) {
    return (

        <div id="credits-container">
            <p>Designed and Built by Yang Xu</p>
            <p>Made with React and JavaScript</p>
        </div>

    );
}

export default Credits;